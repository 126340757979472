// ** Initial State
const initialState = {
    events: [],
    selectedEvent: {}
}

const EventReducer = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_DISABLED_EVENTS":
            return {...state, events: action.events}
        default:
            return state
    }
}

export default EventReducer