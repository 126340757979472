// ** Initial State
const initialState = {
    sponsorships: [],
    selectedSponsorship: {}
}

const SponsorshipReducer = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_SPONSORSHIPS":
            return {...state, sponsorships: action.sponsorships}
        default:
            return state
    }
}

export default SponsorshipReducer