const LandingState = {
    openCart: false,
    sponsorshipType: "all",
    token: localStorage.getItem('accessToken'),
    openModal: false,
    transaction: [],
    checkoutData: [],
    upcomingData: {
        data: [],
        totalCompletedAmount: 0
    },
    //     {
    //         month: "may",
    //         day: "14",
    //         name: "Name (to be Displayed)",
    //         attribution: "John Deo",
    //         relationship: "Father",
    //         mealUpgrade: "Yes",
    //         upgradeDescription: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown",
    //         upgradePrice: 250,
    //         note: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.",
    //         id: "data_number_1"
    //     },
    //     {
    //         month: "may",
    //         day: "14",
    //         name: "Name (to be Displayed)",
    //         attribution: "John Deo",
    //         relationship: "Father",
    //         mealUpgrade: "Yes",
    //         upgradeDescription: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown",
    //         upgradePrice: 250,
    //         note: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.",
    //         id: "data_number_2"
    //     }
    // ],
    completedData: {
        data: [],
        totalUpcomingAmount: 0
    },
    //     {
    //         month: "may",
    //         day: "14",
    //         name: "Name (to be Displayed)",
    //         attribution: "John Deo",
    //         relationship: "Father",
    //         mealUpgrade: "Yes",
    //         upgradeDescription: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown",
    //         upgradePrice: 250,
    //         note: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.",
    //         id: "data_number_1"
    //     },
    //     {
    //         month: "may",
    //         day: "14",
    //         name: "Name (to be Displayed)",
    //         attribution: "John Deo",
    //         relationship: "Father",
    //         mealUpgrade: "Yes",
    //         upgradeDescription: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown",
    //         upgradePrice: 250,
    //         note: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.",
    //         id: "data_number_2"
    //     }
    // ],
    news: [
        {
            text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknownLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknownLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown",
            images: [";", ",", ","]
        },
        {
            text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknownLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknownLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown",
            images: [";", ",", ","]
        },
        {
            text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknownLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknownLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown",
            images: [";", ",", ","]
        }
    ]
}


function CalendarReducer(state = LandingState, action) {
    const temp = { ...state }
    switch (action.type) {
        case "opencart":
            return {...state, openCart: true}
        case "closeCart":
            return {...state, openCart: false}
        case "removeItem": {
            const checkoutData = JSON.parse(localStorage.getItem('checkoutData'))
            checkoutData.splice(action.payload, 1)
            localStorage.setItem('checkoutData', JSON.stringify(checkoutData))
            return temp
        }
        case "addItem": {
            const checkoutData = JSON.parse(localStorage.getItem('checkoutData')) || []
            checkoutData.push(action.payload)
            localStorage.setItem('checkoutData', JSON.stringify(checkoutData))
            return temp
        }
        case "changeSponsorshipType":
            return {...state, sponsorshipType: action.payload}
        case "setCheckoutData": {
            const checkoutData = JSON.parse(localStorage.getItem('checkoutData'))
            checkoutData[action.payload.index] = action.payload.data
            localStorage.setItem('checkoutData', JSON.stringify(checkoutData))
            return temp
        }
        case "setTransaction": {
            return {...state, transaction: action.payload}
        }
        case "setUpcomingData":
            return {...state, upcomingData: action.payload}
        case "setCompletedData":
            return {...state, completedData: action.payload}
        case "setNews":
            return {...state, news: action.payload}
        case "modalAction":
            return {...state, openModal: action.payload}
        default:
            return state
    }
}

export default CalendarReducer
